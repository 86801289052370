/* stylelint-disable scss/at-extend-no-missing-placeholder, scss/comment-no-empty, scss/no-global-function-names, block-no-empty, declaration-block-no-duplicate-properties, keyframes-name-pattern, no-descending-specificity, property-no-unknown, selector-class-pattern, selector-pseudo-class-no-unknown, selector-pseudo-element-no-unknown */

@import '@tnt-map/dist/styles';
@import '@harbor/dist/styles/harbor';
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'tippy.js/dist/tippy';
@import 'tippy.js/themes/light';
@import 'app_variables';
@import 'bootstrap/scss/bootstrap';
@import 'bs-compat';
@import 'addons';
@import 'application';
@import 'ember-power-select/app/styles/ember-power-select';
@import 'left-nav';
@import 'v2-static';
@import 'index';
@import 'safety';
@import 'rates';
@import 'form';
@import 'booking';
@import 'auth';
@import 'container';
@import 'terminals';
@import 'search';
@import 'platform';
@import 'notifications';
@import 'navbar';
@import 'admin';
@import 'notify';
@import 'webhook-card';
@import 'webhook-form';

// custom styles for ember-notifys
@import 'offer';
@import 'delivery-instructions';
@import 'location';
@import 'blocks';
@import 'lists';
@import 'settings';
@import 'shipments';
@import 'cargo-products';
@import 'shipping-lines';
@import 'start-tracking-form';
@import 'dashboard';
@import 'tnt-widget';
@import 'containers-dashboard';
@import 'smart-filter';
@import 'feedback-button';
@import 'containers-modal';
@import 'drag-and-drop';
@import 'edit-trial';
@import 'tnt/chips';

html {
  scrollbar-gutter: stable;

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: #fff;
  }

  ::-webkit-scrollbar-track {
    background-color: #e9e9e9;
    background-clip: content-box;
    border-radius: 10px;
    border: 2px solid transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    background-clip: content-box;
    border-radius: 10px;
    border: 2px solid transparent;
  }

  &.no-overflow {
    padding-right: 10px;
    scrollbar-gutter: unset;
    overflow: hidden;
  }
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;

  &.no-scroll {
    overflow: hidden !important;
  }
}

.tab-pane:focus {
  outline: none;
}

.card-block {
  flex: 1 1 auto;
  padding: $card-spacer-x;
}

b,
strong {
  font-weight: 600;
}

.font-weight-semibold {
  font-weight: 500;
}

.row-100 {
  width: 100%;
}

.flex {
  display: flex;
}

.inline {
  display: inline-block;
}

.home-preload {
  .spinner {
    display: inline-block;
    width: 36px;
    height: 36px;
    animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

.ember-application .home-preload {
  display: none;
}

// Extending bootstrap's utility classes to support [m,p]-{orientation}-{length} syntax
$orientation: t b l r;

@for $i from 1 through 3 {
  @each $o in $orientation {
    .m-#{$o}-#{$i} {
      @extend .m#{$o}-#{$i};
    }
    .p-#{$o}-#{$i} {
      @extend .p#{$o}-#{$i};
    }
  }
}

// Set the same light grey placeholder color across browsers
::input-placeholder {
  color: #848d95;
}

:placeholder {
  /* Firefox 18- */
  color: #848d95;
}

::placeholder {
  /* Firefox 19+ */
  color: #848d95;
}

:input-placeholder {
  /* Internet Explorer 10+ */
  color: #848d95;
}

//
// Route Specific Styles
//

.search-route {
  .navbar {
    .fake-search-box {
      display: none;
    }
  }

  .recent-updates-footer {
    display: none;
  }

  .archive-container {
    display: none;
  }

  .check-again {
    display: none;
  }
}

.my-containers-route {
  .save-container {
    display: none;
  }
}

.center-search {
  margin-top: 3em;

  h1 {
    font-size: 3rem;
    font-weight: 500;
  }

  h2 {
    font-size: 1.5rem;
    font-weight: 300;
  }
}

@include media-breakpoint-up(sm) {
  .center-search {
    margin-top: 100px;
  }
}

header {
  // margin: 20px 0;
  h1 {
    font-weight: 300;
  }

  mark {
    background-color: white;
  }
}

@include media-breakpoint-down(sm) {
  h1 {
    font-size: 1.75rem;
  }
}

.footer {
  .footer-body {
    margin-top: 20px;
    text-align: center;

    li {
      margin-right: 1em;
    }

    .spacer {
      margin-left: 30px;
      margin-right: 30px;
    }

    button.btn {
      border: none;
    }
  }
}

footer {
  .links {
    a {
      margin-left: 1rem;
    }
  }
}

.waiting {
  display: inline-block;
  overflow: hidden;
  height: 1.3em;
  margin-top: -0.3em;
  line-height: 1.5em;
  vertical-align: text-bottom;
}

.waiting::after {
  display: inline-table;
  white-space: pre;
  text-align: left;
}

@keyframes spin10 {
  to {
    transform: translateY(-15em);
  }
}

.waiting.dots::after {
  content: '⠋\A⠙\A⠹\A⠸\A⠼\A⠴\A⠦\A⠧\A⠇\A⠏';
  animation: spin10 1s steps(10) infinite;
}

.loading .d {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
  background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
  background-repeat: no-repeat;
  background-size: 800px 104px;
  height: 10px;
  position: relative;
  margin-bottom: 10px;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.ember-modal-overlay {
  height: 100vh;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 101;
}

.ember-modal-dialog {
  z-index: 101;

  &.ember-modal-dialog-in-place {
    position: relative;
  }

  &.modal-lg {
    border-radius: 5px;
  }

  &.modal-md {
    max-width: 600px;
  }

  .modal-content {
    max-height: 80vh;
  }

  .modal-body {
    overflow-y: auto;
  }
}

.ember-modal-overlay.translucent {
  background-color: rgba(#000, 0.5);
}

.subtle-outline-label {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  border: 1px solid #bbb;
  color: #bbb;
}

.subtle-label {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  border: 1px solid #bbb;
  color: #fff;
  background-color: #bbb;
}

.label-headline-row {
  padding-bottom: 7px;

  .label-headline {
    display: inline;
    padding-left: 5px;
    padding-bottom: 7px;
  }
}

@include media-breakpoint-up(sm) {
  .mailinglist-footer {
    @include border-radius(2px);

    box-shadow: 0 0 30px 0 rgb(0 0 0 / 10%);
  }

  .modal-dialog {
    margin: 90px auto;
  }
}

.recent-updates {
  box-shadow: 0 0 30px 0 rgb(0 0 0 / 10%);

  a.try-it-now {
    width: 25%;
  }
}

.mailinglist-footer {
  margin-top: 50px;
  background-color: white;
  color: #555;
  padding: 1rem 0;

  .left-section {
    .recent-update-headline {
      font-weight: 300;
      font-size: 1.2rem;
    }

    .recent-update-subheadline {
      font-size: 1rem;
      display: block;
    }
  }

  .right-section {
    border-left: 1px solid rgb(72 72 72 / 20%);

    .get-updates-headline {
      font-weight: 300;
      font-size: 1.2rem;
    }
  }
}

nav.navbar {
  z-index: 100;

  .navbar-brand {
    color: white;
    vertical-align: middle;
    padding-bottom: 5px !important;
    padding-top: 5px !important;
  }

  .navbar-nav {
    .dropdown-menu {
      display: block;
    }
  }

  .fake-search-box {
    width: 300px;

    .input-group-addon {
      width: 20px;
    }

    .fake-search-input {
      height: 30px;
      background-color: white;
      border-left: 1px solid #ccc;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      cursor: text;
    }

    .fake-search-copy {
      padding-top: 3px;
      padding-left: 7px;
      color: #aaa;
    }
  }
}

.display-inline {
  display: inline;
}

.spacer {
  padding-left: 7px;
  padding-right: 7px;
  color: #888;
}

.flash {
  padding: 5px;
  margin-bottom: 0;
  border: 1px solid transparent;
  border-radius: 0.25rem;

  &.flash-n-fadeout {
    animation: flash-n-fadeout 1s 1;
    animation: flash-n-fadeout 1s 1;
    animation-fill-mode: forwards;
    animation-delay: 3s;
    animation-delay: 3s; /* Safari and Chrome */
    animation-fill-mode: forwards;

    &.flash-warning {
      color: #8a6d3b;
      background-color: #fcf8e3;
      border-color: #faf2cc;
    }

    &.flash-success {
      color: #3c763d;
      background-color: #dff0d8;
      border-color: #d0e9c6;
    }
  }

  @keyframes flash-n-fadeout {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }

  @keyframes flash-n-fadeout {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }
}

@include media-breakpoint-down(sm) {
  nav.navbar {
    .fake-search-box {
      width: 150px;

      .fake-search-input {
      }
    }
  }
}

[data-ember-action]:not(:disabled) {
  cursor: pointer;
}

.btn-xs {
  @extend .btn-sm;

  font-size: $font-size-xs;
}

.complete-profile {
  .title {
    font-size: 1.2em;
    margin-bottom: 0.5em;
  }

  .profile-fields {
    display: inline-block;

    .form-group {
      margin-right: 0.5rem;
    }
  }
}

.ember-basic-dropdown-content {
  background-color: #fff;

  .dropdown-menu {
    position: relative;
    display: block;
  }
}

.dropdown-menu {
  &.tntmenu {
    position: relative;
    display: block;
    box-shadow: 0 3px 5px rgb(0 0 0 / 10%);
  }
}

.tag-circle {
  margin-left: 0.3rem;
  padding: 0.25em 0.5em;
  border-radius: 1rem;
  font-size: 70%;
}

// Tooltip
.ember-tooltip,
.ember-popover {
  visibility: hidden;

  &[aria-hidden='false'] {
    visibility: visible;
  }

  .tooltip-inner {
    background: #3a3c47;
    padding: 0;
  }
}

.badge-outline {
  background: none;
  border: 1px solid $badge-default-bg;
  color: darken($badge-default-bg, 20%);

  @include border-radius($badge-pill-border-radius);

  padding: $badge-padding-y 0.75em;

  &.badge-default {
    @include badge-status-variant($badge-default-bg);
  }

  &.badge-primary {
    @include badge-status-variant($badge-primary-bg);
  }

  &.badge-success {
    @include badge-status-variant($badge-success-bg);

    color: darken($badge-success-bg, 10%);
  }

  &.badge-info {
    @include badge-status-variant($badge-info-bg);
  }

  &.badge-warning {
    @include badge-status-variant($badge-warning-bg);
  }

  &.badge-danger {
    @include badge-status-variant($badge-danger-bg);
  }

  &.badge-light {
    @include badge-status-variant(#9d9d9d);
  }
}

.force-select {
  user-select: all; /* Chrome 49+ */
  user-select: all; /* Firefox 43+ */
  user-select: all; /* No support yet */
  user-select: all; /* Likely future */
}

.no-select {
  user-select: none; /* Chrome 49+ */
  user-select: none; /* Firefox 43+ */
  user-select: none; /* No support yet */
  user-select: none; /* Likely future */
}

.fa-outline-circle {
  border: 1px solid black;
  padding: 0.25rem;
  border-radius: 2rem;
}

.fa,
.svg-inline--fa {
  &.far {
    margin-right: 5px;
  }

  &.fal {
    margin-left: 5px;
  }

  &.fa-primary {
    path {
      fill: $brand-primary;
    }
  }

  &.fa-secondary {
    path {
      fill: $brand-secondary;
    }
  }

  &.fa-success {
    path {
      fill: $brand-success;
    }
  }

  &.fa-info {
    path {
      fill: $brand-info;
    }
  }

  &.fa-warning {
    path {
      fill: $brand-warning;
    }
  }

  &.fa-danger {
    path {
      fill: $brand-danger;
    }
  }

  &.fa-green {
    path {
      fill: $light-green;
    }
  }
}

.fa-xxs {
  width: 0.5rem;
}

.fa-xs {
  width: 0.75rem;
}

.fa-sm {
  width: 0.85rem;
}

.fa-md {
  width: 1rem;
}

.fa-lg {
  width: 1.33rem;
}

.fa-xl {
  width: 1.5rem;
}

.text-sm {
  font-size: 0.9em;
}

.blur {
  filter: blur(3px);
  filter: blur(3px);
  filter: blur(3px);
  filter: blur(3px);
  filter: blur(3px);
  opacity: 0.95;
}

.text-brand {
  color: $green;
}

abbr {
  font-weight: 500;
  text-decoration: none !important;
}

.metabaseDashboard {
  width: 1px;
  min-width: 100%;
}

.Canny_BadgeContainer .Canny_Badge {
  position: absolute;
  top: 1px;
  right: -1px;
  border-radius: 10px;
  background-color: red;
  padding: 5px;
  border: 1px solid white;
}

.highlight-on-hover {
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #f5f5f5;
  }
}

input.x-toggle[type='checkbox'] {
  display: none;
}

.x-toggle-container {
  label {
    margin: unset;
  }
}

.x-toggle:checked + label > .x-toggle-light.x-toggle-btn {
  background-color: #007bff;
}

.opt-in-toggle {
  .x-toggle-light.x-toggle-btn {
    width: 36px;
    height: 20px;
  }
}

.containers-dropdown {
  .ember-power-select-search {
    &-input {
      border-radius: 4px;
      border: 1px solid #dddee0;
      padding: 4px;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.056px;
      color: #151719;
    }
  }

  .ember-power-select-group {
    &-name {
      display: block;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.056px;
      color: #151719;
      font-weight: 700;
      padding: 6px 4px 6px 12px;
    }
  }

  .ember-power-select-option {
    display: block;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.056px;
    color: #151719;
    padding: 6px 4px 6px 20px;

    &--no-matches-message {
      padding: 6px;
    }
  }
}

.containers-dropdown-general {
  @extend .containers-dropdown;

  .ember-power-select-option {
    padding: 6px 12px;
  }
}

.generic-dropdown {
  .ember-power-select-option {
    display: block;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.056px;
    color: #151719;
    padding: 6px 8px;

    &--no-matches-message {
      padding: 6px;
    }
  }
}

.icon-cloud {
  img {
    height: 24px;
  }
}

.event-separator {
  margin: 0;
  padding: 0;
  border-color: #e5e7eb;
  margin-left: 1.5rem;
}

.current-event {
  background-color: #ecfdf5;
}

.current-event:hover {
  background-color: #d1fae5;
}
